export default new Map([
  [
    'astrahan',
    {
      city: 'Астрахань',
      address: 'ул. Свердлова, д. 97/2/96, магазин "Мир камней"',
      phone: '+7 927-577-78-41',
      person: 'Омаров Расул Магомедович',
      time: '9.00-18.00',
      mapSid: '5vVNGfee8JoIT5M109He7rHmpmN6_RS6',
    },
  ],
  [
    'bryansk',
    {
      city: 'Брянск',
      address: 'ул. Октябрьская, д. 7',
      phone: '+7 910-337-65-51',
      person: 'Перов Илья Андреевич',
      mapSid: '8jrWs5qGenJqHrq0qkNqj3UliiqtaBdG',
    },
  ],
  [
    'volgograd',
    {
      city: 'Волгоград',
      address: 'ул. 64-й армии, д. 30',
      phone: '+7 961-058-04-86',
      person: 'Матросова Маргарита Андреевна',
      time: 'вт-пт: 10-18, сб: 10-16, вых: вс, пн',
      mapSid: 'fdda32800c991ef9080d263cb18aca7502c0f5939bdafedead8cc093df6d886a',
    },
  ],
  [
    'voronezh',
    {
      city: 'Воронеж',
      address: 'ул. Кольцовская, д. 64 офис 4',
      phone: '+7 906-585-10-69',
      person: 'Севостьянов Григорий Владимирович',
      time: '11.00-17.00',
      mapSid: 'FkGRLQtoLVdlJSkhN8TKeOdmFOxfB8uS',
    },
  ],
  [
    'ekaterinburg',
    {
      city: 'Екатеринбург',
      person: 'Ищем представителя',
    },
  ],
  [
    'zheleznogorsk',
    {
      city: 'Железногорск',
      region: 'Курская область',
      address: 'ул. Гайдара, д. 4, магазин «Магнит»',
      phone: '+7 951-330-16-69',
      person: 'Овакимян Тачат Гарегинович',
      mapSid: 'CLe57O6KkhOa2QwRErJwa_VBIFe6Q9e3',
    },
  ],
  [
    'irkutsk',
    {
      city: 'Иркутск',
      address: 'ул. Ржанова, д. 164, ТЦ «Солнечный»',
      phone: '+7 964-119-17-35',
      person: 'Демин Вячеслав Алексеевич',
      mapSid: 'ONDWc8qwiLew686qmJSUbyYaZ_HEZeh7',
    },
  ],
  [
    'yoshkarOla',
    {
      city: 'Йошкар-Ола',
      phone: '+7 917-705-05-48',
      person: 'Павлова Ольга Анатольевна',
    },
  ],
  [
    'kaliningrad',
    {
      city: 'Калининград',
      address: 'ул. Дмитрия Донского, 7/11, офис 102',
      phone: '+7 981-477-09-09',
      person: 'Павлова Светлана Владимировна',
      time: 'с 9.00 до 18.00 ',
      mapSid: 'oByxWPY_fe5KGbpvq1ARJJNVPCNN3zbH',
    },
  ],
  [
    'kirov',
    {
      city: 'Киров',
      address: 'ул. Е. Кочкиной, 3а, Юго-Западный рынок',
      phone: '+7 953-676-74-80',
      person: 'Сидоров Сергей Геннадьевич',
      mapSid: 'Fwsql43j84J33KGO-D7fMEmT9cURb3n2',
    },
  ],
  [
    'moskva',
    {
      city: 'Москва',
      address: '3-й Крутицкий переулок, 13',
      phone: '+7 926-392-22-21',
      person: 'Маилов Давид Леванович',
      time: 'пн-пт: 10:00-20:00, сб: 10:00-17:00',
      mapSid: 'V_8xiRUtHE44tzUfp-gbQDefBvNynnvH',
    },
  ],
  [
    'nizhnij-novgorod',
    {
      city: 'Нижний Новгород',
      address: 'ул.Фруктовая, 3/3, ТК «Конфетти»',
      phone: '+7 (831) 414-52-00',
      person: 'Ильичев Андрей Владимирович',
      mapSid: 'MoVMPTuQUldiX3r_3I-f_LYcEVjLqFOe',
    },
  ],
  [
    'novokuzneck',
    {
      city: 'Новокузнецк',
      address: 'ул. Климасенко, д. 4',
      phone: '+7 951-572-48-35',
      person: 'Липкань Алексей Игоревич',
      region: 'Кемеровская область',
      mapSid: 'KA1J-vuQBt6Go7eUcr9x3qjjBlFaanjB',
    },
  ],
  [
    'novokujbyshevsk',
    {
      city: 'Новокуйбышевск',
      address: 'ул.Свердлова, д. 10, ювелирная мастерская',
      phone: '+7 905-017-71-76',
      person: 'Павлушина Ирина Николаевна',
      region: 'Самарская область',
      time: '11.00-17.00',
      mapSid: 'E8DWpPYy9dr5naMPvMzdBwHSbtLCYVuF',
    },
  ],
  [
    'orenburg',
    {
      city: 'Оренбург',
      address: 'ул. Кирова, д. 26, магазин «Галина»',
      phone: '+7 903-367-41-10',
      person: 'Красников Сергей Владимирович',
      mapSid: '5iknbPuZ8XZnjvm9MCEPIHXS41kHm7-P',
    },
  ],
  [
    'petrozavodsk',
    {
      city: 'Петрозаводск',
      address: 'пр. Ленина, д. 25/43, магазин «Свет»',
      phone: '+7 953-531-50-17',
      person: 'Юлдашев Мухамад Алиджонович',
      region: 'Республика Карелия',
      mapSid: 'M5uyKR0UA3XqU9y56krT5RyIpzMrRRUy',
    },
  ],
  [
    'ufa',
    {
      city: 'Салават',
      address: 'ул. Ленина 24а, офис 15',
      phone: '+7 917-461-26-83, +7 987-058-35-51',
      person: 'Денисенко Екатерина Юрьевна',
      region: 'Республика Башкортостан',
      time: 'пн-сб: 10:00-19:00, вс: 10:00-18:00',
      mapSid: 'HlSzcIBTWsABAeSiUQ9NyENy5HTQfAZ4',
    },
  ],
  [
    'sankt-peterburg',
    {
      city: 'Санкт-Петербург',
      address: 'ул.Белы Куна, д. 16',
      phone: '+7 911-118-55-06',
      person: 'Быстрицкий Анатолий Андреевич',
      mapSid: '9LXVwpGTkPr3Cz9qK_A0F7-eOMFaHlW8',
    },
  ],
  [
    'saransk',
    {
      city: 'Саранск',
      address: 'ул. Полежаева, д. 155',
      phone: '+7 906-160-57-06',
      person: 'Храмихин Андрей Анатольевич',
      region: 'Республика Мордовия',
      mapSid: '_SkjlYrtbtRhlKudLygbn6TS0PTl6JA0',
    },
  ],
  [
    'slavgorod',
    {
      city: 'Славгород',
      address: 'ул. Карла Маркса, д. 143, рынок',
      phone: '+7 923-728-25-39',
      person: 'Строзенко Сергей Александрович',
      region: 'Алтайский край',
      mapSid: 'gpZ5XiqPuLIZhE-qT00uY885GoI0Bqv0',
    },
  ],
  [
    'taganrog',
    {
      city: 'Таганрог',
      address: 'ул. Мало-Почтовая, д. 54А',
      phone: '+7 918-550-13-13',
      person: 'Погорелов Сергей Александрович',
      region: 'Ростовская область',
      mapSid: 'TYsrXMpZ7gPCFR6UKodqL98FDBrsVvzD',
    },
  ],
  [
    'tver',
    {
      city: 'Тверь',
      address: 'ул. Маршала Захарова, д. 17, в здании ООО "ТЭМЗ"',
      phone: '+7 920-161-68-94',
      person: 'Егоров Андрей Владимирович',
      mapSid: 'Re-hkVtyMnMP2frsgJxcPOSvW2OV0Tv5',
    },
  ],
  [
    'tomsk',
    {
      city: 'Томск',
      address: 'ул. Алексея Беленца, д. 17, ювелирная мастерская',
      phone: '+7 906-956-66-08',
      person: 'Попов Сергей Владимирович',
      time: '10.00-18.00',
      mapSid: 'qN0iqTtc101UaQD2KEq86vYSf6b-OL08',
    },
  ],
  [
    'ulyanovsk',
    {
      city: 'Ульяновск',
      address: 'ул. Марата, д. 7, оф. 13',
      phone: '+7 927-831-50-12',
      person: 'Горшунов Александр Владимирович',
      mapSid: 'ToBDlbhGUiluLt0f1x7hx9LcQmkwanH8',
    },
  ],
  [
    'habarovsk',
    {
      city: 'Хабаровск',
      address: 'ул. Тихоокеанская, д. 182, Рынок "Депо 2", 5 павильон, место 110а',
      phone: '+7 924-103-40-40',
      person: 'Закарян Алина Гургеновна',
      mapSid: '-afsL00RM-r1IFurJqOnNf2oVzDb8pEM',
    },
  ],
  [
    'cheboksary',
    {
      city: 'Чебоксары',
      address: 'ул. Ю. Гагарина, д. 12, оф.2',
      phone: '+7 960-304-33-08',
      person: 'Александров Алексей Сергеевич',
      region: 'Чувашская Республика',
      mapSid: 'I7H1FuMvInsRbtGzAHLIJ8iCi8zPAMFY',
    },
  ],
]);
